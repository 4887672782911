import {useAppState} from '@app/modules/apps/context';
import AuthRateMetric from '@app/modules/metrics/authRate';
import {logCenter} from '@app/modules/tracking/logCenter';
import {useCallback, useEffect} from 'react';
import type {AuthRateLog} from '@app/modules/metrics/authRate/types';
import {useAppId} from '@app/modules/apps/useAppId';
import {getUniqId} from '@app/modules/tracking/utils';

/**
 * Hook that automatically sends a pageview / formview event to REC
 * in order to be able to measure, later, the conversion rate between
 * pageview and auth backend result response.
 *
 * @see useLogAuthResult
 */
export function useLogAuthConversionStart(
	journey_type?: AuthRateLog['journey_type'],
) {
	const {appName} = useAppState();
	const {appId} = useAppId();

	useEffect(() => {
		if (!appId) return;
		logCenter.log(
			new AuthRateMetric({
				app_id: appId,
				app_name: appName,
				device_uniq_id: getUniqId(),
				journey_type,
				status_int: 0,
				status: 'ATTEMPT',
				step_type: journey_type ? 'page-view' : 'form-view',
			}).serialize(),
		);
		logCenter.flush();
	}, [journey_type, appName, appId]);
}

export function useLogAuthResult(journey_type: AuthRateLog['journey_type']) {
	const {appName} = useAppState();
	const {appId} = useAppId();
	return useCallback(
		(success: boolean) => {
			logCenter.log(
				new AuthRateMetric({
					app_id: appId,
					app_name: appName,
					device_uniq_id: getUniqId(),
					journey_type,
					status_int: success ? 1 : 0,
					status: success ? 'SUCCESS' : 'FAILURE',
					step_type: 'result',
				}).serialize(),
			);
			logCenter.flush();
		},
		[journey_type, appName, appId],
	);
}
