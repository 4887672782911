import {useAppState} from '@app/modules/apps/context';
import {useCallback, useEffect, useState} from 'react';
import {
	useRedirection,
	useRedirectionAfterAuth,
} from '@app/modules/redirection/hooks';
import {useAuthConfig} from '../auth/useAuthConfig';
import {useLogAuthConversionStart} from '../metrics/useAuthRateMetrics';
import {useAutolog} from '@app/hooks/auth/useAutolog';
import {AuthStatus} from '@app/hooks/auth/useAutolog/utils';
import {useUserData} from './useUserData';
import type {AuthResult} from '../auth/types';
import {setClientCookie} from '@app/utils/cookies';
import {useAuthVitalsMetrics} from '../metrics/useAuthVitalsMetrics';
import {usePrevious} from '@deezer/react-utils';

export const useUnloggedController = () => {
	const {status} = useAutolog();
	const redirect = useRedirection();

	useEffect(() => {
		if (status === AuthStatus.logged) {
			redirect();
		}
	}, [redirect, status]);

	return {
		isLoading: status === AuthStatus.loading,
	};
};

export function useAuthSuccess(afterAuthSteps?: string[]) {
	const {mutate} = useAutolog();
	const {refetchUser} = useUserData();
	const {appName} = useAppState();
	const {logAuthError} = useAuthVitalsMetrics();
	const [isLoading, setLoading] = useState(false);
	const redirectAfterAuth = useRedirectionAfterAuth();

	const onAuthSuccess = useCallback(
		async (tokens?: AuthResult) => {
			if (isLoading) return;
			setLoading(true);
			let displayPicker = false;
			try {
				if (tokens) {
					await mutate({
						status: AuthStatus.logged,
						data: {...tokens, user_id: parseInt(tokens.user_id, 10)},
					});
				}
				const user = await refetchUser();

				if (!user?.id) return;

				if (appName === 'Zen') {
					setClientCookie(
						'isSubProfile',
						user.familyStatus.parent && !user.familyStatus.isSubAccount
							? 'true'
							: 'false',
					);
				}

				// Display picker -> family selection activated for the app + family member connected
				displayPicker =
					user?.familyStatus.isActive &&
					user?.familyStatus.isEnabled &&
					user?.familyStatus.childCount > 0 &&
					!user?.familyStatus.isSubAccount;
			} catch (error: any) {
				logAuthError({
					e: {
						name: 'PostAuthError',
						message: 'post auth getUserData has failed',
					},
					journey_type: 'common',
					gateway_method: 'user.getData',
					payload: error,
				});
			} finally {
				redirectAfterAuth(afterAuthSteps, displayPicker);
			}
		},
		[
			afterAuthSteps,
			appName,
			isLoading,
			logAuthError,
			mutate,
			redirectAfterAuth,
			refetchUser,
		],
	);
	return {onAuthSuccess, isLoading};
}

export function useAuthController(afterAuthSteps?: string[]) {
	const {status} = useAutolog();
	const prevStatus = usePrevious(status);
	const authConfig = useAuthConfig();
	useLogAuthConversionStart();
	const {onAuthSuccess, isLoading} = useAuthSuccess(afterAuthSteps);

	useEffect(() => {
		// Return early if the user is not logged in or if the fetch is already complete
		if (
			status === AuthStatus.logged &&
			prevStatus !== AuthStatus.unlogged &&
			!isLoading
		) {
			onAuthSuccess();
		}
	}, [onAuthSuccess, status, isLoading, prevStatus]);

	return {
		authConfig,
		isLoading: !authConfig.data || status !== AuthStatus.unlogged,
		authCallback: onAuthSuccess,
	};
}
