import type {AuthResult} from '@app/hooks/auth/types';
import {createContext} from '@deezer/react-utils';

export type AuthSuccessCallback = (tokens: AuthResult) => Promise<void>;
type AuthFormContext = {
	onSuccess: AuthSuccessCallback;
	afterAuthSteps?: string[];
};

const [AuthFormProvider, useAuthForm] = createContext<AuthFormContext>({
	name: 'AuthForm',
});
export {AuthFormProvider, useAuthForm};
