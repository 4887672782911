import type {RemoteClientV2} from '@deezer/logcenter';
import {RECLog, RECLogContext} from '@deezer/logcenter';
import type {AppName} from '@app/types/appName';
import type {AuthRateJourneyType, AuthRateLog, StepType} from './types';

const recLogContext = new RECLogContext({
	name: 'user.auth_rate_web',
	version: '1.0.0',
});

class AuthRateMetrics extends RECLog<
	AuthRateLog & RemoteClientV2 & {country?: string}
> {
	#app_name: AppName;
	#app_id?: number;
	#device_uniq_id?: string;
	#journey_type?: AuthRateJourneyType;
	#status_int: 0 | 1;
	#status?: 'SUCCESS' | 'FAILURE' | 'ATTEMPT';
	#step_type: StepType;
	#tags?: Record<string, string>;

	constructor(params: AuthRateLog) {
		super({
			type: 'user.auth_rate_web',
			version: '1.0.0',
			...recLogContext,
		});

		this.#app_name = params.app_name;
		this.#app_id = params.app_id;
		this.#device_uniq_id = params.device_uniq_id;
		this.#journey_type = params.journey_type;
		this.#status_int = params.status_int;
		this.#status = params.status;
		this.#step_type = params.step_type;
		this.#tags = params.tags;
	}

	serialize() {
		return {
			...super.serialize(),
			app_id: this.#app_id,
			device_uniq_id: this.#device_uniq_id,
			journey_type: this.#journey_type,
			status_int: this.#status_int,
			status: this.#status,
			step_type: this.#step_type,
			tags: this.#tags,
			platform: {
				...this.platform,
				app: {
					...this.platform.app,
					name: this.#app_name.toLocaleLowerCase(),
				},
			},
		};
	}
}

export default AuthRateMetrics;
