import {AUTOLOG_COOKIE_KEY} from '@app/constants';
import type {AuthConfig} from '@app/hooks/auth/useAuthConfig';
import {
	type Intent,
	isSmartJourney,
	isEmailLoginJourney,
	isEmailRegJourney,
	isPhoneLoginJourney,
} from '@app/hooks/auth/useAuthConfig';
import {useTranslation} from '@app/hooks/useTranslation';
import {ReCaptcha} from '@app/modules/reCaptcha';
import {AUTOLOG_STATUS} from '@app/types/appName';
import {useToast} from '@tempo/core';
import {config} from '@app/modules/config';
import {getClientCookie, removeClientCookie} from '@app/utils/cookies';
import dynamic from 'next/dynamic';
import {useEffect, useState} from 'react';
import {AuthFormProvider, type AuthSuccessCallback} from './context';

export type AuthFormProps = {
	afterAuthSteps?: string[];
	authConfig?: AuthConfig;
	intent?: Intent;
	onSuccess: AuthSuccessCallback;
};

const LoginForm = dynamic(() => import('./Login'));
const RegForm = dynamic(() => import('./signup'));
const SmartJourneyForm = dynamic(() => import('./smart'));

export const AuthForm: React.FC<AuthFormProps> = ({
	afterAuthSteps,
	authConfig: data,
	intent = 'login',
	onSuccess,
}) => {
	const toast = useToast();
	const t = useTranslation();
	const [formType, setFormType] = useState<
		keyof AuthConfig['journeys'] | undefined
	>();

	const updateFormName = (type: keyof AuthConfig['journeys'] | undefined) => {
		setFormType(type);
	};

	useEffect(() => {
		if (
			getClientCookie(AUTOLOG_COOKIE_KEY) === AUTOLOG_STATUS.FAIL &&
			!toast.isActive('toast-autolog-id')
		) {
			toast({
				id: 'toast-autolog-id',
				title: t('error.anerroroccurredpleasetryagain'),
				status: 'error',
				isClosable: true,
				onCloseComplete() {
					removeClientCookie(AUTOLOG_COOKIE_KEY);
				},
				position: 'top-right',
				duration: 5000,
			});
		}
	}, [toast, t]);

	if (!data) return null;
	const formName = data.default_journeys[intent];
	const formData = data.journeys[formType ?? formName];

	return (
		<AuthFormProvider value={{onSuccess, afterAuthSteps}}>
			<ReCaptcha apiKey={config.get('recaptchaEnterpriseKey')}>
				{isSmartJourney(formData) && <SmartJourneyForm config={formData} />}
				{!!(isEmailLoginJourney(formData) || isPhoneLoginJourney(formData)) && (
					<LoginForm config={formData} updateFormName={updateFormName} />
				)}
				{isEmailRegJourney(formData) && <RegForm config={formData} />}
			</ReCaptcha>
		</AuthFormProvider>
	);
};
